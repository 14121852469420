body {
  font-family: "Lato", sans-serif;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0px;
  right: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 10000;
}

.dropdown-label{
  font-weight: 700;
}

.dropdown-list{
  width: 100%;
  height: 30px;
  padding: 5px;
}

.profile-pic-container {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin: 3px;
}

.pagination {
max-width: 300px !important;
display: flex !important;
align-items: center !important;
justify-content: space-between !important;
list-style: none !important;
cursor: pointer !important;
}

.pagination a {
padding: 5px !important;
border-radius: 5px !important;
border: 1px solid #FFA3C7 !important;
color: #FFA3C7 !important;
}

.pagination__link {
font-weight: bold !important;
}

.pagination__link--active a {
color: #fff !important;
background: #FFA3C7 !important;
}

.pagination__link--disabled a {
color: rgb(198, 197, 202) !important;
border: 1px solid rgb(198, 197, 202) !important;
}

.modal-image{
height: 86vh;
object-fit: cover;
}
.modal-video{
height: 86vh;
object-fit: cover;
}
.card-image{
height: 580px;
width: 408;
object-fit: contain;
}
.card-video{
height: 580px;
object-fit: contain;
}
